import { useCallback, useEffect, useState } from 'react'
import config from 'config'

const writeKey = config.writeKey
const dataPlaneUrl = config.dataPlaneUrl

const useRudderStack = () => {
  const [rudderStack, setRudderStack] = useState(null)
  const [ready, setReady] = useState(false)
  const callback = useCallback(() => {
    setReady(true)
  })
  useEffect(() => {
    if (!rudderStack) {
      import('rudder-sdk-js').then((rudderResult) => {
        rudderResult.load(writeKey, dataPlaneUrl, {
          integrations: { All: true },
        })

        rudderResult.ready(callback)

        setRudderStack(rudderResult)
      })
    }
  }, [])

  return { rudderStack, ready }
}

export default useRudderStack
