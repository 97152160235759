import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Alert, AlertTitle } from '@material-ui/lab'
import backgroundVisaMobile from 'assets/banner-visa-mobile.png'
import backgroundVisa from 'assets/banner-visa.png'
import { ReactComponent as BRLCIcon } from 'assets/brlc.svg'
import { ReactComponent as CancelIcon } from 'assets/cancel.svg'
import BankSlipIconButton from 'assets/download-icon.svg'
import CopyIcon from 'assets/icon-copy.svg'
import { ReactComponent as BankSlipIcon } from 'assets/method-bank-slip.svg'
import { ReactComponent as CreditIcon } from 'assets/method-credit.svg'
import { ReactComponent as PixIcon } from 'assets/method-pix.svg'
import LoadingIcon from 'assets/pix-loading.svg'
import { ReactComponent as ProcessingIcon } from 'assets/processing-icon.svg'
import { ReactComponent as WarningIcon } from 'assets/warning-icon.svg'
import WithoutPix from 'assets/without-pix.png'
import clsx from 'clsx'
import { BannerButton } from 'components/NewHeader/styles'
import { installmentDefault } from 'components/PaymentForm/CreditCardForm'
import BannerLink from 'components/ui/BannerLink'
import { infinitePayLinkBanner } from 'config'
import Image from 'next/image'
import { useEffect, useState } from 'react'

export const FormBox = withStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      backgroundColor: theme.palette.common.white,
      zIndex: 10,
      height: 0,
      width: '100%',

      transition: 'width .369s ease-out, height .369s ease-out',
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        width: 0,
        height: '100vh',
      },
      '& .tab-selector': {
        position: 'relative',
        margin: '0 auto',
        width: '100%',
        maxWidth: 440,
        padding: theme.spacing(2),
      },
    },
    expand: {
      height: 'fit-content',
      minHeight: '100vh',
      overflow: 'initial',
      justifyContent: 'space-between',
      '& form': {
        [theme.breakpoints.up('sm')]: {
          minHeight: 'calc(100vh - 168px)',
        },
      },
      [theme.breakpoints.up('md')]: {
        height: '100vh',
        overflow: 'auto',
        width: '100%',
        '-webkit-overflow-scrolling': 'touch',
        '& form': {
          minHeight: '100vh',
        },
      },
    },
  }),
  {
    name: 'FormBox',
  },
)(({ expand, classes, ...props }) => (
  <Box className={clsx(classes.root, { [classes.expand]: expand })} {...props} />
))

export const FlowBanner = withStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: 0,
      paddingBottom: '41.6%',
      textDecoration: 'none',
    },
    backgroundVisa: {
      background: `transparent url(${backgroundVisa.src}) no-repeat center`,
      backgroundSize: 'auto 100%',
    },
    backgroundMobile: {
      background: `transparent url(${backgroundVisaMobile.src}) no-repeat center`,
      backgroundSize: 'auto 100%',
    },
    textContainer: {
      textAlign: 'left',
      marginLeft: '6%',
      display: 'flex',
      flexDirection: 'column',
    },
    textTypography: {
      fontWeight: 600,
      display: 'inline-block',
      fontSize: '9px',
      lineHeight: '14px',
      color: '#000000',
      width: '180px',
    },
    textTypographyTitle: {
      fontSize: '16px',
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      height: '36px',
      color: '#000000',
      width: '180px',
      lineHeight: '18px',
      marginTop: '8%',
    },
    button: {
      background: '#000000',
      color: '#FFFFFF',
      '&:hover': {
        transform: 'scale(0.94)',
        background: '#000000',
      },
      height: '21px',
      width: '118px',
      fontSize: '8px',
    },
  }),
  {
    name: 'FlowBanner',
  },
)(({ expand, classes, ...props }) => {
  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <BannerLink
      href={infinitePayLinkBanner.mobile}
      className={clsx(classes.root, isMobile ? classes.backgroundMobile : classes.backgroundVisa)}
      {...props}
    >
      <Box className={classes.textContainer}>
        <Typography className={clsx(classes.textTypographyTitle)}>
          Link de Pagamento InfinitePay
        </Typography>
        <Typography className={clsx(classes.textTypography)}>
          Venda segura e recebimento na hora. <br />O link favorito de quem vende online.
        </Typography>
        <BannerButton className={classes.button}>Cadastre-se grátis</BannerButton>
      </Box>
    </BannerLink>
  )
})

export const HeaderBanner = withStyles(
  (theme) => ({
    root: {
      width: '100%',
      backgroundColor: '#212121',
      minHeight: 122,
      color: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    tittleTypography: {
      display: 'flex',
      gap: '0.25rem',
      height: 64,
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: '19px',
    },
    divider: {
      width: 'calc(100% - 1rem)',
      margin: '1rem 1rem',
      height: '1px',
      border: 'none',
      margin: ' 0',
      background: '#757575',
    },
    textTypography: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 'calc(100% - 1rem)',
      fontWeight: 500,
      padding: '1rem 0',
      fontSize: '16px',
      color: '#ffffff',
    },
    nameTypography: {
      width: '75%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    textAmount: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      justifyContent: 'center',
      alignItems: 'end',
    },
    titleAmount: {
      whiteSpace: 'nowrap',
    },
    subtitleAmount: {
      whiteSpace: 'nowrap',
      fontSize: '11px',
    },
  }),
  {
    name: 'FlowBanner',
  },
)(
  ({
    invoiceAmount,
    selectedMethod,
    classes,
    handle,
    name,
    dynamicParams,
    valueWithFees,
    installments,
  }) => {
    const [amount, setAmount] = useState('')
    const [amountSubtitle, setAmountSubtitle] = useState('')
    useEffect(() => {
      if (installments !== installmentDefault && installments && selectedMethod === 0) {
        setAmount(
          `${installments}x de R$${valueWithFees?.credit_card[installments - 1]?.installment_value
            .toFixed(2)
            .replace('.', ',')}`,
        )
        setAmountSubtitle(
          `Valor total: R$${valueWithFees?.credit_card[installments - 1]?.total_value}`,
        )
      } else {
        setAmount(`R$${invoiceAmount.toFixed(2).replace('.', ',')}`)
        setAmountSubtitle('')
      }
    }, [valueWithFees, installments, dynamicParams])

    return (
      <Box className={clsx(classes.root)}>
        <Box className={clsx(classes.tittleTypography)}>
          <BRLCIcon width={24} height={24} viewBox="0 0 38 38" /> ${handle}
        </Box>
        <hr className={clsx(classes.divider)} />
        <Box className={clsx(classes.textTypography)}>
          <Box className={clsx(classes.nameTypography)}>{name}</Box>
          <Box fontWeight={400} className={clsx(classes.textAmount)}>
            <Box fontWeight={400} className={clsx(classes.titleAmount)}>
              {amount}
            </Box>
            <Box fontWeight={400} className={clsx(classes.subtitleAmount)}>
              {amountSubtitle}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  },
)

export const PaymentBox = withStyles(
  (theme) => ({
    root: {
      overflowX: 'hidden',
      [theme.breakpoints.up('md')]: {
        width: '50vw',
      },
    },
  }),
  {
    name: 'PaymentBox',
  },
)(({ classes, ...props }) => (
  <Box position="relative" className={clsx(classes.root)} {...props}></Box>
))

export const PaymentCloseIcon = withStyles(
  (theme) => ({
    root: {
      right: '0rem',
      top: '0.6rem',
      zIndex: '2',
      '& svg path': {
        fill: '#fff',
      },
      [theme.breakpoints.up('sm')]: {
        '& svg path': {
          fill: '#000',
        },
      },
      [theme.breakpoints.up('md')]: {
        right: '3rem',
        top: '2.5rem',
      },
    },
  }),
  {
    name: 'PaymentCloseIcon',
  },
)(({ classes, onCloseForm, ...props }) => (
  <Box position="absolute" className={clsx(classes.root)} {...props}>
    <IconButton className={``} aria-label="Fechar" onClick={onCloseForm}>
      <CloseIcon color="inherit" />
    </IconButton>
  </Box>
))
export const PixAlert = withStyles(
  (theme) => ({
    root: {
      '& .MuiAlert-action': {
        alignItems: 'start',
      },
    },
  }),
  {
    name: 'PixAlert',
  },
)(({ classes, onCloseForm, ...props }) => (
  <Alert severity="success" className={clsx(classes.root)} {...props}>
    <AlertTitle>Código Pix copiado</AlertTitle>
    Agora é só fazer um Pix Copia e Cola no seu app de pagamentos
  </Alert>
))

export const BankSlipAlert = withStyles(
  (theme) => ({
    root: {
      '& .MuiAlert-action': {
        alignItems: 'start',
      },
    },
  }),
  {
    name: 'BankSlipAlert',
  },
)(({ classes, onCloseForm, ...props }) => (
  <Alert severity="success" className={clsx(classes.root)} {...props}>
    <AlertTitle>Código do boleto copiado</AlertTitle>
    Agora é só realizar o pagamento no seu banco de preferência
  </Alert>
))

export const BankSlipDownloadAlert = withStyles(
  (theme) => ({
    root: {
      '& .MuiAlert-action': {
        alignItems: 'start',
      },
    },
  }),
  {
    name: 'BankSlipAlert',
  },
)(({ classes, onCloseForm, ...props }) => (
  <Alert severity="success" className={clsx(classes.root)} {...props}>
    <AlertTitle>PDF do boleto baixado com sucesso</AlertTitle>
    Agora é só realizar o pagamento no seu banco de preferência
  </Alert>
))

export const AnimatedLoading = withStyles(
  (theme) => ({
    root: {},
    iconRotation: {
      animationName: 'rotate-loading',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationDuration: '1s',
    },

    '@global @keyframes rotate-loading': {
      '0%': {
        transform: 'rotate(0)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
  {
    name: 'Loading',
  },
)(({ classes, amount, handle }) => (
  <Box className={clsx(classes.root)}>
    <Image className={clsx(classes.iconRotation)} src={LoadingIcon} width={48} height={48} />
  </Box>
))

export const CopyButton = withStyles((theme) => ({
  endIcon: {
    // position: 'absolute',
    right: '1.25rem',

    height: 'fit-content',
    lineHeight: 0,
    margin: 'auto 0 auto 0.75rem',
  },
  loading: {
    backgroundColor: '#212121',
    '&:after': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundSize: '150px 100%',
      animationName: 'shimmer',
      animationDuration: '1s',
      animationIterationCount: 'infinite',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 0',
      filter: 'blur(8px)',
    },
  },
}))(({ children, loading = false, classes, ...props }) => {
  const { loading: classLoading, ...cls } = classes
  return (
    <Button
      {...props}
      classes={cls}
      className={clsx({
        [classLoading]: loading,
      })}
      fullWidth
      variant="contained"
      color="primary"
      type="submit"
      endIcon={
        loading ? (
          <CircularProgress color="inherit" size={20} thickness={5} disableShrink />
        ) : (
          <Image src={CopyIcon} width={15} height={18} />
        )
      }
    >
      {loading ? '' : children}
    </Button>
  )
})

export const DownloadBankSlipBtn = withStyles((theme) => ({
  endIcon: {
    // position: 'absolute',
    right: '1.25rem',

    height: 'fit-content',
    lineHeight: 0,
    margin: 'auto 0 auto 0.75rem',
  },
  loading: {
    backgroundColor: '#212121',
    '&:after': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundSize: '150px 100%',
      animationName: 'shimmer',
      animationDuration: '1s',
      animationIterationCount: 'infinite',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 0',
      filter: 'blur(8px)',
    },
  },
}))(({ children, loading = false, classes, ...props }) => {
  const { loading: classLoading, ...cls } = classes
  return (
    <Button
      {...props}
      classes={cls}
      className={clsx({
        [classLoading]: loading,
      })}
      fullWidth
      variant="contained"
      color="primary"
      type="submit"
      endIcon={
        loading ? (
          <CircularProgress color="inherit" size={20} thickness={5} disableShrink />
        ) : (
          <Image src={BankSlipIconButton} width={15} height={18} />
        )
      }
    >
      {loading ? '' : children}
    </Button>
  )
})

export const BankSlipViewButton = withStyles((theme) => ({
  endIcon: {
    right: '1.25rem',
    height: 'fit-content',
    lineHeight: 0,
    margin: 'auto 0 auto 0.75rem',
  },
  loading: {
    '&:after': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundSize: '150px 100%',
      animationName: 'shimmer',
      animationDuration: '1s',
      animationIterationCount: 'infinite',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 0',
      filter: 'blur(8px)',
    },
  },
}))(({ children, loading = false, classes, ...props }) => {
  const { loading: classLoading, ...cls } = classes
  return (
    <Button
      {...props}
      classes={cls}
      className={clsx({
        [classLoading]: loading,
      })}
      fullWidth
      variant="contained"
      color="primary"
      type="submit"
      endIcon={
        loading && <CircularProgress color="inherit" size={20} thickness={5} disableShrink />
      }
    >
      {loading ? '' : children}
    </Button>
  )
})

export const BasePaymentButton = withStyles((theme) => ({
  root: {
    width: '100%',
    height: '3.5rem',
    borderRadius: '0.5rem',
    background: '#F5F5F5',
    fontWeight: 500,
    fontSize: '14px',
    overflow: 'hidden',
    '& svg': {
      marginRight: '0.5rem',
      '& path': {
        fill: '#000',
      },
    },
    '&:hover': {
      transform: 'scale(1)',
      border: '1px solid #212121',
      '& path': {
        fill: '#212121',
      },
    },
    '& i': {
      position: 'absolute',
      fontSize: '11px',
      lineHeight: '11px',
      fontStyle: 'normal',
      fontWeight: 700,
      display: 'inline-block',
      background: '#E5FFDE',
      color: '#0D962B',
      padding: '4px',
      top: 0,
      right: 0,
    },
  },
  selected: {
    border: '1px solid #000000',
  },
}))(({ children, selected, classes, ...props }) => {
  const { selected: classSelected, ...cls } = classes
  return (
    <Button
      {...props}
      classes={cls}
      className={clsx({
        [classSelected]: selected,
      })}
    >
      {children}
    </Button>
  )
})
export const CreditPaymentButton = withStyles((theme) => ({
  root: {},
}))(({ ...props }) => {
  return (
    <BasePaymentButton {...props}>
      <CreditIcon /> Crédito
    </BasePaymentButton>
  )
})
export const PixPaymentButton = withStyles((theme) => ({
  root: {},
}))(({ ...props }) => {
  return (
    <BasePaymentButton {...props}>
      <PixIcon /> Pix
    </BasePaymentButton>
  )
})
export const BankSlipButton = withStyles((theme) => ({
  root: {},
}))(({ ...props }) => {
  return (
    <BasePaymentButton {...props}>
      <BankSlipIcon /> Boleto
    </BasePaymentButton>
  )
})

export const PixError = withStyles(
  (theme) => ({
    root: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
    },
    text: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    iconContainer: {
      display: 'inline-flex',
      padding: '1rem',
      borderRadius: '50%',
      background: '#F5F5F5',
    },
    cancel: {
      width: '1.5rem',
      height: '1.5rem',
    },
    iconRotation: {
      // animationName: 'rotate-loading',
      // animationTimingFunction: 'linear',
      // animationIterationCount: 'infinite',
      // animationDuration: '1s',
    },

    '@global @keyframes rotate-loading': {
      '0%': {
        transform: 'rotate(0)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
  {
    name: 'Loading',
  },
)(({ classes, amount, handle }) => (
  <Box className={clsx(classes.root)}>
    <Box className={clsx(classes.iconContainer)}>
      <CancelIcon className={clsx(classes.cancel)} />
    </Box>
    <Box className={clsx(classes.text)}>Erro no QR Code</Box>
  </Box>
))

export const PixLoading = withStyles(
  (theme) => ({
    root: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
    },
    text: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    iconContainer: {
      display: 'inline-flex',
      padding: '1rem',
      borderRadius: '50%',
      background: '#F5F5F5',
    },
    loading: {
      width: '3rem',
      height: '3rem',
    },
    iconRotation: {
      // animationName: 'rotate-loading',
      // animationTimingFunction: 'linear',
      // animationIterationCount: 'infinite',
      // animationDuration: '1s',
    },

    '@global @keyframes rotate-loading': {
      '0%': {
        transform: 'rotate(0)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
  {
    name: 'Loading',
  },
)(({ classes, amount, handle }) => (
  <Box className={clsx(classes.root)}>
    <AnimatedLoading className={clsx(classes.loading)} />

    <Box className={clsx(classes.text)}>
      Carregando <br />o QR Code
    </Box>
  </Box>
))

export const BankSlipData = withStyles(
  (theme) => ({
    root: {
      color: '#616161',
      fontWeight: '400',
      fontSize: '16px',
      padding: '16px',
      border: '1px solid #EEEEEE',
      borderRadius: '16px',
    },
    data: {
      fontWeight: '500',
      width: 295,
      color: '#000000',
    },
  }),
  {
    name: 'Data',
  },
)(({ classes, data }) => (
  <Box className={clsx(classes.root)}>
    <Box>Código do boleto:</Box>
    <Box className={clsx(classes.data)}>{data}</Box>
  </Box>
))

export const BankSlipLoading = withStyles(
  (theme) => ({
    root: {
      color: '#616161',
      fontWeight: '400',
      fontSize: '16px',
      padding: '16px',
      border: '1px solid #EEEEEE',
      borderRadius: '16px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
    },
    text: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    iconContainer: {
      display: 'inline-flex',
      padding: '1rem',
      borderRadius: '50%',
      background: '#F5F5F5',
    },
    loading: {
      width: '3rem',
      height: '3rem',
    },

    '@global @keyframes rotate-loading': {
      '0%': {
        transform: 'rotate(0)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
  {
    name: 'Loading',
  },
)(({ classes, data }) => (
  <Box className={clsx(classes.root)}>
    <AnimatedLoading className={clsx(classes.loading)} />

    <Box className={clsx(classes.text)}>
      Carregando os dados <br />
      do boleto
    </Box>
  </Box>
))

export const BankSlipError = withStyles(
  (theme) => ({
    root: {
      color: '#616161',
      fontWeight: '400',
      fontSize: '16px',
      padding: '16px',
      border: '1px solid #EEEEEE',
      borderRadius: '16px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',
    },
    text: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    iconContainer: {
      display: 'inline-flex',
      padding: '1rem',
      borderRadius: '50%',
      background: '#F5F5F5',
    },
    cancel: {
      width: '1.5rem',
      height: '1.5rem',
    },

    '@global @keyframes rotate-loading': {
      '0%': {
        transform: 'rotate(0)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
  {
    name: 'Loading',
  },
)(({ classes, amount, handle }) => (
  <Box className={clsx(classes.root)}>
    <Box className={clsx(classes.iconContainer)}>
      <CancelIcon className={clsx(classes.cancel)} />
    </Box>
    <Box className={clsx(classes.text)}>Erro no Boleto</Box>
  </Box>
))

export const ProcessingBox = withStyles(
  (theme) => ({
    root: {
      color: '#616161',
      fontWeight: '400',
      fontSize: '16px',
      padding: '16px 16px 16px 24px',
      borderRadius: '16px',
      backgroundColor: '#FFF5E5',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      fontWeight: '700',
      marginBottom: '8px',
    },
    data: {
      fontSize: '15px',
    },
  }),
  {
    name: 'ProcessingBox',
  },
)(({ classes, title, data }) => (
  <Box className={clsx(classes.root)}>
    <Box className={clsx(classes.title)}>
      <ProcessingIcon /> {title}
    </Box>
    <Box>{data}</Box>
  </Box>
))

export const WarningBox = withStyles(
  (theme) => ({
    root: {
      color: '#616161',
      fontWeight: '400',
      fontSize: '16px',
      padding: '16px 16px 16px 24px',
      borderRadius: '16px',
      backgroundColor: '#FFF5E5',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      fontWeight: '700',
      marginBottom: '8px',
    },
    data: {
      fontSize: '15px',
    },
  }),
  {
    name: 'WarningBox',
  },
)(({ classes, title, data }) => (
  <Box className={clsx(classes.root)}>
    <Box className={clsx(classes.title)}>
      <WarningIcon /> {title}
    </Box>
    <Box>{data}</Box>
  </Box>
))

export const WithoutPixView = withStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '400px',
      gap: '24px',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        height: '560px',
      },
    },
    pixImage: {
      width: '360px',
      height: '250px',
      [theme.breakpoints.up('md')]: {
        width: '259px',
        height: '180px',
      },
    },
    text: {
      fontWeight: 400,
      fontSize: '16px',
      padding: '0 8px',
      [theme.breakpoints.up('md')]: {
        fontSize: '20px',
        padding: '0',
      },
    },
    handle: {
      fontWeight: 600,
      fontSize: '16px',
      [theme.breakpoints.up('md')]: {
        fontSize: '20px',
      },
    },
  }),
  {
    name: 'WithoutPixView',
  },
)(({ classes, handle }) => (
  <Box className={clsx(classes.root)}>
    <Image className={clsx(classes.pixImage)} src={WithoutPix} />
    <Typography className={clsx(classes.text)}>
      Opa, o pagamento via Pix está desabilitado, se tiver dúvidas fale com{' '}
      <b className={clsx(classes.handle)}>{handle}</b>
    </Typography>
  </Box>
))
