import { useCallback, useEffect, useState } from 'react'
import cardValidator, { number } from 'card-validator'
import { Box, InputAdornment, CircularProgress, SvgIcon, Fade } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form'
import * as Y from 'yup'
import { usePaymentContext } from 'context/PaymentContext'
import { getBrandByCardType, CVV } from 'components/Brands'
import { UFStates, initialValues, validate, required, schema as formSchema } from './schema'
import HelpButton from './HelpButton'
import BaseForm, { Select, TextField, useFieldForChange, useFormSchemaContext } from '../'
import createDecorator from 'final-form-focus'
import { installmentDefault } from 'components/PaymentForm/CreditCardForm'

import { maskCPFOrCNPJ } from 'utils/form-masks'

cardValidator.creditCardType.addCard({
  niceType: 'Hipercard',
  type: 'hipercard',
  patterns: [38, 60],
  gaps: [4, 8, 12],
  lengths: [16],
  code: {
    name: 'CVV',
    size: 3,
  },
})

cardValidator.creditCardType.addCard({
  niceType: 'AMEX',
  type: 'amex',
  patterns: [34, 37],
  gaps: [4, 11, 17],
  lengths: [15],
  code: {
    name: 'CVV',
    size: 4,
  },
})

const focusOnError = createDecorator()
const Form = ({ ...props }) => {
  return (
    <BaseForm
      decorators={[focusOnError]}
      {...props}
      {...{ required, validate, schema: formSchema, initialValues }}
    />
  )
}
export default Form

const labelSettings = () => ({
  root: {
    '& label': {
      pointerEvents: 'initial',
    },
  },
})
export const PhoneNumber = withStyles(labelSettings())(
  ({ name = 'phone_number', label = 'Celular', ...props }) => {
    const schema = useFormSchemaContext()
    const config = usePaymentContext()
    const form = useForm()

    useEffect(() => {
      if (config.signedUser?.phone_number) {
        const phoneNumber = config.signedUser?.phone_number?.replace(
          /\+55(\d{2})(\d{5})(\d{4})/gi,
          '+55 ($1) $2–$3',
        )
        form.change(name, phoneNumber)
      }
    }, [form, name, config.signedUser])
    return (
      <TextField
        {...props}
        label={label}
        name={name}
        type="tel"
        inputProps={{
          id: name,
          mask: '{+55} (00) M0000–0000',
          definitions: { M: /9/ },
          autoComplete: 'tel',
        }}
        InputLabelProps={{ htmlFor: name }}
        placeholder="+55 (00) 00000–0000"
        required={schema.required[name]}
      />
    )
  },
)

export const Email = withStyles(labelSettings())(
  ({ name = 'email', label = 'E-mail', ...props }) => {
    const schema = useFormSchemaContext()
    const config = usePaymentContext()
    const form = useForm()
    useEffect(() => {
      if (config.signedUser?.email) {
        form.change(name, config.signedUser?.email)
      }
    }, [form, name, config.signedUser])
    return (
      <TextField
        {...props}
        label={label}
        name={name}
        type="email"
        inputProps={{
          id: name,
          mask: /^[\wÀ-ú+@.\-_]+$/,
          indeterminate: true,
          autoComplete: 'email',
        }}
        InputLabelProps={{ htmlFor: name }}
        placeholder="Digite seu e-mail"
        required={schema.required[name]}
      />
    )
  },
)

export const Installments = ({
  name = 'installments',
  label = 'Número de parcelas',
  data,
  ...props
}) => {
  const config = usePaymentContext()
  const form = useForm()
  const formSchema = useFormSchemaContext()
  const fieldSchema = Y.reach(formSchema.schema, name)
  fieldSchema.spec.meta = {
    amount: config.userData.amount.valueAsNumber,
  }
  return (
    <Select
      {...props}
      label={label}
      name={name}
      defaultValue={
        !config.invoiceData.info?.transfer_fees && config.maxInstallments
          ? config.maxInstallments
          : installmentDefault
      }
      required={formSchema.required[name]}
      data={data}
    />
  )
}

export const CardNumber = withStyles(labelSettings())(
  ({ name = 'card_number', label = 'Número do cartão', ...props }) => {
    const schema = useFormSchemaContext()
    const { input } = useFieldForChange(name)

    const cardObj = cardValidator.number(input.value)
    const cardType = cardObj.card?.type

    const brand = cardType === 'american-express' ? 'amex' : cardType
    const CardBrand = getBrandByCardType(brand)

    return (
      <Box>
        <TextField
          {...props}
          label={label}
          name={name}
          type="text"
          inputProps={{
            id: name,
            mask: [
              {
                mask: '0000 00000 000000',
                startsWith: '34',
              },
              {
                mask: '0000 00000 000000',
                startsWith: '37',
              },
              {
                mask: '0000 0000 0000 0000',
                startsWith: '',
              },
            ],
            dispatch: (appended, dynamicMasked) => {
              const number = (dynamicMasked.value + appended).replace(/\D/g, '')

              return dynamicMasked.compiledMasks.find((m) => number.indexOf(m.startsWith) === 0)
            },
            autoComplete: 'cc-number',
            inputMode: 'numeric',
          }}
          InputProps={{
            endAdornment: CardBrand && (
              <InputAdornment position="end">
                <SvgIcon component={CardBrand} />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ htmlFor: name }}
          placeholder="0000 0000 0000 0000"
          required={schema.required[name]}
        />
      </Box>
    )
  },
)

export const CardExpDate = withStyles(labelSettings())(
  ({ name = 'card_date', label = 'Data de vencimento', ...props }) => {
    const schema = useFormSchemaContext()
    return (
      <TextField
        {...props}
        label={label}
        name={name}
        type="text"
        inputProps={{
          id: name,
          mask: '00/00',
          autoComplete: 'cc-exp',
          inputMode: 'numeric',
        }}
        InputLabelProps={{ htmlFor: name }}
        placeholder="MM/AA"
        required={schema.required[name]}
      />
    )
  },
)

export const CardCVV = withStyles((theme) => ({
  root: {
    '& label': {
      pointerEvents: 'initial',
      '& button': {
        right: 0,
        bottom: '-0.5em',
        position: 'absolute',
        transform: 'translateY(0.1em)',
        zIndex: 2,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
}))(({ name = 'card_cvv', label = 'CVV', onHelp, ...props }) => {
  const schema = useFormSchemaContext()
  const config = usePaymentContext()
  const { getFieldState } = useForm()
  const cardNameField = getFieldState('card_number')

  const toggleAmex = cardValidator.number(cardNameField?.value)?.card?.type === 'american-express'

  const eventHandler = {
    onClick: (config.deviceDetection.device && onHelp) || undefined,
    onMouseEnter: (!config.deviceDetection.device && onHelp) || undefined,
  }

  return (
    <TextField
      {...props}
      label={
        <>
          {label}
          <HelpButton {...eventHandler} />
        </>
      }
      name={name}
      type="text"
      inputProps={{
        id: name,
        mask: toggleAmex ? '0000' : '000',
        autoComplete: 'cc-csc',
        inputMode: 'numeric',
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SvgIcon component={CVV} />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{ htmlFor: name }}
      placeholder={toggleAmex ? '****' : '***'}
      required={schema.required[name]}
    />
  )
})

export const CardHolderName = withStyles(labelSettings())(
  ({ name = 'card_holder_name', label = 'Nome impresso no cartão', ...props }) => {
    const schema = useFormSchemaContext()
    return (
      <TextField
        {...props}
        label={label}
        name={name}
        inputProps={{
          id: name,
          mask: /^[A-Za-zÀ-ÖØ-öø-ÿ'˜`´ˆ\s]+$/,
          indeterminate: true,
          autoComplete: 'cc-name',
        }}
        InputLabelProps={{ htmlFor: name }}
        placeholder="Digite o nome impresso no cartão"
        required={schema.required[name]}
      />
    )
  },
)

export const CPFCNPJ = withStyles(labelSettings())(
  ({ name = 'card_holder_document', label = 'Confirme o CPF/CNPJ', ...props }) => {
    const schema = useFormSchemaContext()

    const handleInputChange = (event) => {
      const { value } = event.target
      const mask = maskCPFOrCNPJ(value)

      event.target.value = mask
    }

    return (
      <TextField
        {...props}
        label={label}
        name={name}
        type="text"
        inputProps={{
          id: name,
          autoComplete: 'on',
          inputMode: 'numeric',
          onChange: handleInputChange,
        }}
        InputLabelProps={{ htmlFor: name }}
        placeholder="Digite seu CPF/CNPJ aqui"
        required={schema.required[name]}
      />
    )
  },
)

export const CEP = withStyles(labelSettings())(
  ({ name = 'billing_zip_code', label = 'CEP', fetching = false, ...props }) => {
    const form = useForm()
    const onPaste = useCallback(
      (event) => {
        let paste = (event.clipboardData || window.clipboardData).getData('text')
        paste = paste.replace(/(\d{5})(-|–)?(\d{3})/gi, '$1–$3')
        form.change(name, paste)
        event.preventDefault()
      },
      [form],
    )
    const schema = useFormSchemaContext()
    return (
      <TextField
        {...props}
        label={label}
        name={name}
        type="text"
        inputProps={{
          ...(props.inputProps ?? {}),
          id: name,
          mask: '00000-000',
          onPaste: onPaste,
          autoComplete: 'postal-code',
          inputMode: 'numeric',
        }}
        InputProps={{
          endAdornment: (
            <Fade
              in={fetching}
              style={{
                transitionDelay: '100ms',
              }}
              unmountOnExit
            >
              <InputAdornment position="end">
                <CircularProgress size={24} thickness={5} disableShrink />
              </InputAdornment>
            </Fade>
          ),
        }}
        InputLabelProps={{ htmlFor: name }}
        placeholder="00000-000"
        required={schema.required[name]}
      />
    )
  },
)

export const Street = withStyles(labelSettings())(
  ({ name = 'billing_street', label = 'Endereço', ...props }) => {
    const schema = useFormSchemaContext()
    return (
      <TextField
        {...props}
        label={label}
        name={name}
        inputProps={{ id: name, autoComplete: 'address-level3' }}
        InputLabelProps={{ htmlFor: name }}
        placeholder="Digite o nome da rua"
        required={schema.required[name]}
      />
    )
  },
)

export const City = withStyles(labelSettings())(
  ({ name = 'billing_city', label = 'Cidade', ...props }) => {
    const schema = useFormSchemaContext()
    return (
      <TextField
        {...props}
        label={label}
        name={name}
        inputProps={{ id: name, autoComplete: 'address-level2' }}
        InputLabelProps={{ htmlFor: name }}
        placeholder="Digite a cidade"
        required={schema.required[name]}
      />
    )
  },
)

export const StreetNumber = withStyles(labelSettings())(
  ({ name = 'billing_number', label = 'Número', ...props }) => {
    const schema = useFormSchemaContext()
    return (
      <TextField
        {...props}
        label={label}
        name={name}
        type="text"
        inputProps={{ id: name, autoComplete: 'address-level3', inputMode: 'numeric' }}
        InputLabelProps={{ htmlFor: name }}
        maxLength={20}
        placeholder="Digite o número"
        required={schema.required[name]}
      />
    )
  },
)

export const State = withStyles(labelSettings())(
  ({ name = 'billing_state', label = 'Estado', data = UFStates, ...props }) => {
    const schema = useFormSchemaContext()
    return (
      <Select
        displayEmpty
        {...props}
        label={label}
        name={name}
        inputProps={{ id: name, autoComplete: 'address-level1' }}
        inputLabelProps={{ htmlFor: name }}
        required={schema.required[name]}
        data={data}
      />
    )
  },
)
