import { Box } from '@material-ui/core'
import { PaymentInfoErrorSubTitle, PaymentInfoErrorTitle } from './styles'
import CancelIcon from 'assets/cancel.svg'
import Image from 'next/image'
export function ExpiredInvoice(userData, url) {
  return (
    <>
      <Box display="flex" flexDirection="column" style={{ gap: '0.5rem' }}>
        <Box textAlign="center">
          <Image src={CancelIcon} width={40} height={40} />
        </Box>
        <PaymentInfoErrorTitle component="h2">Cobrança expirada</PaymentInfoErrorTitle>
        <PaymentInfoErrorSubTitle component="h3">
          Por favor, entre em contato com o vendedor para receber um novo link de cobrança
        </PaymentInfoErrorSubTitle>
      </Box>
    </>
  )
}
