import { ValidAmount } from './ValidAmount'
import { ExpiredInvoice } from './ExpiredInvoice'
import { InvalidData } from './InvalidData'
import { CanceledInvoice } from './CanceledInvoice'
import { usePaymentContext } from 'context/PaymentContext'
import invoiceStatus from 'utils/invoiceStatus'

export function PaymentContent() {
  const { userData, invoiceData } = usePaymentContext()
  if (userData && invoiceData && invoiceData.info) {
    if (invoiceData?.status == invoiceStatus.CANCELED) {
      return CanceledInvoice(userData)
    } else if (invoiceData?.expired) {
      return ExpiredInvoice(userData)
    } else {
      return ValidAmount(userData, invoiceData)
    }
  } else {
    return InvalidData()
  }
}
