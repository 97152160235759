import Display from './Display'
import { HeaderBox } from './styles'

import { Collapse } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectPayments } from 'reducers/payments'

function NewHeader({ ...props }) {
  const payments = useSelector(selectPayments)
  return (
    <HeaderBox shrink={!payments.active} {...props}>
      <Display shrink={payments.active} />
    </HeaderBox>
  )
}

export default NewHeader
