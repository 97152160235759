import { Collapse, Grid, Hidden, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CardsBrands from 'assets/cards-brands.svg'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPayments } from 'reducers/payments'
import { selectSession } from 'reducers/session'
import { selectThreeDS } from 'reducers/threeds'
import { fetchAddressByZipcode, selectZipCodeSearch } from 'reducers/zipcode'
import * as Y from 'yup'
import { usePaymentContext } from '../../context/PaymentContext'

import {
  CardCVV,
  CardExpDate,
  CardHolderName,
  CardNumber,
  CEP,
  City,
  CPFCNPJ,
  Email,
  Installments,
  PhoneNumber,
  State,
  Street,
  StreetNumber,
} from 'components/ui/form/PaymentForm'
import { warningFindByCode, warnings } from 'components/ui/form/PaymentForm/messages'
import { SubmitButton, useFormSchemaContext } from '../../components/ui/form'

import Footer from 'components/Footer'
import FooterInterestAndFines from 'components/FooterInterestAndFines'
import useRudderStack from 'utils/useRudderStack'

export const installmentDefault = 'default'

export const CreditCardForm = ({
  closePopup,
  handlePopup,
  handleSubmit,
  onCloseForm,
  submitting,
  submitted,
  submitError,
  pristine,
  form,
  hasValidationErrors,
  values,
}) => {
  const [amount, setAmount] = useState('')
  const [capture_methods, setCapture_methods] = useState([])
  const theme = useTheme()
  const payments = useSelector(selectPayments)
  const matchesMd = useMediaQuery(theme.breakpoints.up('sm'))
  const session = useSelector(selectSession)
  const zipCodeSearch = useSelector(selectZipCodeSearch)
  const dispatch = useDispatch()
  const config = usePaymentContext()
  const { setInvoiceData } = usePaymentContext()
  const threeds = useSelector(selectThreeDS)
  const formSchema = useFormSchemaContext()
  const isMobile = useMediaQuery('(max-width:600px)')
  const [baseAmount, setBaseAmount] = useState(config.userData?.amount?.valueAsNumber)
  const installments = values.installments
  const credit_installments = config.invoiceData.value_with_fees?.capture_methods?.credit_card
  const disabled =
    pristine ||
    submitting ||
    hasValidationErrors ||
    baseAmount === 0 ||
    threeds.loading ||
    installments === installmentDefault ||
    !installments

  useEffect(() => {
    setCapture_methods(config.invoiceData.value_with_fees?.capture_methods)
  }, [])

  useEffect(() => {
    if (capture_methods?.credit_card) {
      setAmount(
        credit_installments[installments - 1]?.installment_value.toFixed(2).replace('.', ','),
      )
    } else if (capture_methods?.pix) {
      setAmount(capture_methods.pix.total_value?.toFixed(2).replace('.', ','))
    } else if (capture_methods?.bank_slip) {
      setAmount(capture_methods.bank_slip.total_value.toFixed(2).replace('.', ','))
    }
  }, [capture_methods, installments])

  useEffect(() => {
    setBaseAmount(config.userData?.amount?.valueAsNumber)
  }, [config.invoiceData])

  const [installmentsData] = useState(() => {
    const maxInstallments = config.maxInstallments
    return credit_installments
      ? [
          {
            label: (
              <>
                <span>Selecione o número de parcelas</span>
              </>
            ),
            option: 'Selecione o número de parcelas',
            value: installmentDefault,
            disabled: true,
            className: 'option-default',
          },
        ].concat(
          [...new Array(maxInstallments)].map((value, index) => {
            const installment = credit_installments[index].installment
            const installmentAmount = credit_installments[index].installment_value
            let amount = installmentAmount.toFixed(5).replace('.', ',')
            amount = amount.substr(0, amount.length - 3)
            return {
              label: config.invoiceData.info.transfer_fees ? (
                <>
                  <span>{installment}x de</span>
                  <span>&nbsp;R${amount}</span>
                </>
              ) : (
                <>
                  <span>{installment}x sem juros</span>
                  <strong>&nbsp;R${amount}</strong>
                </>
              ),
              option: config.invoiceData.info.transfer_fees
                ? `${installment}x de R$${amount}`
                : `${installment}x sem juros R$${amount}`,
              value: installment,
            }
          }),
        )
      : []
  })

  const onRequestZipCode = (value) => {
    setTimeout(() => {
      dispatch(fetchAddressByZipcode(value.replace(/\D/gi, '')))
    }, 0)
  }

  const onCVVHelp = (event) => {
    handlePopup({
      open: true,
      anchor: config.deviceDetection.desktop && matchesMd && event.currentTarget,
      info: warnings.cvv_help,
    })
  }

  useEffect(() => {
    form.batch(() => {
      const zipCode = Y.reach(formSchema.schema, 'billing_zip_code')
      if (zipCodeSearch.address) {
        zipCode.spec.meta = {
          valid: true,
        }
        form.change('billing_street', zipCodeSearch.address.logradouro)
        form.change('billing_city', zipCodeSearch.address.localidade)
        form.change('billing_state', zipCodeSearch.address.uf)
      } else if (zipCodeSearch.searched) {
        zipCode.spec.meta = {
          valid: false,
        }
        form.change('billing_street', '')
        form.change('billing_city', '')
        form.change('billing_state', '')
      }
    })
  }, [zipCodeSearch.address, zipCodeSearch.searched])

  // Check if was needed to authenticate before try pay again
  useEffect(() => {
    if (session.user && payments.signing) {
      handleSubmit()
    }
  }, [session.user, payments.signing])

  // useEffect(() => {
  //   if (session.error?.startsWith('Email ')) {
  //     handleSubmit()
  //   }
  // }, [session.error])

  // Check submitError condition for Popup Alerts
  useEffect(() => {
    if (submitError || threeds.error) {
      closePopup()
      const info = warningFindByCode(submitError || threeds.error)
      setTimeout(() => {
        handlePopup(() => {
          return {
            open: true,
            info: info,
          }
        })
      }, 100)
    }
  }, [closePopup, handlePopup, submitError, threeds.error])
  const { rudderStack, ready: rudderStackReady } = useRudderStack()

  useEffect(() => {
    if (submitError && rudderStackReady) {
      const info = warningFindByCode(submitError)
      rudderStack.track('Invoice Error', {
        error: info.title,
        code: submitError,
        content: info.content,
      })
    }
  }, [submitError, rudderStackReady])

  useEffect(() => {
    setInvoiceData({
      ...config.invoiceData,
      dynamicParams: {
        installments: installments,
        paymentMethod: config.invoiceData?.dynamicParams?.paymentMethod,
      },
    })
  }, [installments])

  return (
    <>
      <form style={{ marginTop: '20px' }} onSubmit={handleSubmit} noValidate autoComplete="on">
        <Grid container spacing={2}>
          {!isMobile && (
            <Grid item xs={12}>
              <Image
                src={CardsBrands}
                alt="Bandeiras"
                width={262}
                height={36}
                hspace="5"
                vspace="5"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <PhoneNumber />
          </Grid>
          <Grid item xs={12}>
            <Email />
          </Grid>
          <Grid item xs={12}>
            <Installments data={installmentsData} />
          </Grid>
          <Grid item xs={12}>
            <CardNumber />
          </Grid>
          {isMobile && (
            <Grid item xs={12}>
              <Image
                src={CardsBrands}
                alt="Bandeiras"
                width={262}
                height={36}
                hspace="5"
                vspace="5"
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <CardExpDate />
          </Grid>
          <Grid item xs={6}>
            <CardCVV onHelp={onCVVHelp} />
          </Grid>
          <Grid item xs={12}>
            <CardHolderName />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Dados do titular do cartão</Typography>
          </Grid>
          <Grid item xs={12}>
            <CPFCNPJ />
          </Grid>
          <Grid item xs={12}>
            <CEP inputProps={{ onComplete: onRequestZipCode }} fetching={zipCodeSearch.fetching} />
          </Grid>
          <Grid item xs={12}>
            <Collapse in={!!zipCodeSearch.searched}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Street />
                </Grid>
                <Grid item xs={12}>
                  <City />
                </Grid>
                <Grid item xs={6}>
                  <StreetNumber />
                </Grid>
                <Grid item xs={6}>
                  <State />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <br />
            <SubmitButton
              disabled={disabled}
              submitting={(submitting && !submitted) || threeds.loading}
              aria-invalid={hasValidationErrors}
            >
              {installments === installmentDefault || !installments
                ? 'Pagar cobrança'
                : `Pagar em ${installments}x de R$${amount}`}
            </SubmitButton>
          </Grid>
          <Grid item xs={12}>
            <FooterInterestAndFines invoiceData={config.invoiceData} />
            <Hidden mdUp>
              <Footer
                style={{
                  margin: '0 auto',
                }}
              />
            </Hidden>
            <br />
          </Grid>
        </Grid>
      </form>
    </>
  )
}
