import invoiceStatus from './invoiceStatus'

export const hasValidUserData = (userData) => {
  return !!userData && !!userData.amount
}
export const canPayInvoice = (invoiceData) => {
  return (
    invoiceData.status === invoiceStatus.PENDING ||
    invoiceData.status === invoiceStatus.OVERDUE ||
    invoiceData.type === 'template'
  )
}
export const canPay = (userData, invoiceData) => {
  const showPayButton =
    hasValidUserData(userData) &&
    (!invoiceData ||
      (invoiceData?.status === invoiceStatus.PENDING && !invoiceData.expired) ||
      invoiceData.type === 'template' ||
      invoiceData.status === invoiceStatus.OVERDUE)

  if (invoiceData) {
    return showPayButton && canPayInvoice(invoiceData)
  }
  return showPayButton
}

export default canPay
