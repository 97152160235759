import { withStyles } from '@material-ui/styles'
import { formatCurrency, formatDate, formatPercentage } from 'utils/helpers/formatValues'

import hasInterestAndFines from 'utils/helpers/hasInterestAndFines'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    fontSize: '12px',
    color: '#616161',
    padding: '12px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '36px',
    },
  },
})

const FooterText = withStyles(styles)(({ classes, dueDate, text }) => (
  <span className={classes.root}>
    Após o vencimento ({dueDate}) será cobrado {text}
  </span>
))

function FooterInterestAndFines({ invoiceData }) {
  const showFooterInterestAndFines = hasInterestAndFines(invoiceData)
  if (!showFooterInterestAndFines) return null

  const fineType = invoiceData?.info?.fine_type
  const formattedDueDate = formatDate(invoiceData?.due_date)

  let text = []
  if (invoiceData?.info?.fine && invoiceData?.info?.fine > 0) {
    const fineValue =
      fineType === 'fixed'
        ? formatCurrency(invoiceData?.info?.fine)
        : formatPercentage(invoiceData?.info?.fine)
    text.push(`multa de ${fineValue}`)
  }

  if (invoiceData?.info?.interest && invoiceData?.info?.interest > 0) {
    const interestValue = formatPercentage(invoiceData?.info?.interest)
    text.push(`${invoiceData?.info?.fine ? ' e' : ''} juros de ${interestValue} ao mês`)
  }

  return <FooterText dueDate={formattedDueDate} text={text} />
}

export default FooterInterestAndFines
