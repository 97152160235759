import { Box } from '@material-ui/core'
import { PaymentInfoErrorSubTitle, PaymentInfoErrorTitle } from './styles'
import CancelIcon from 'assets/cancel.svg'
import Image from 'next/image'

export function CanceledInvoice() {
  return (
    <>
      <Box display="flex" flexDirection="column" style={{ gap: '0.5rem' }}>
        <Box textAlign="center">
          <Image src={CancelIcon} width={40} height={40} />
        </Box>
        <PaymentInfoErrorTitle component="h2">Sua cobrança foi cancelada</PaymentInfoErrorTitle>
        <PaymentInfoErrorSubTitle component="h3">
          Por favor, solicite uma nova cobrança
        </PaymentInfoErrorSubTitle>
      </Box>
    </>
  )
}
