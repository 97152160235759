export const maskCNPJ = (value) => {
  return value
    .replace(/\D/g, '') //Remove anything that is not a digit
    .replace(/^(\d{2})(\d)/, '$1.$2') //Add a dot between the second and third digits
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') //Add a dot between the fifth and sixth digits
    .replace(/\.(\d{3})(\d)/, '.$1/$2') //Add a slash between the eighth and ninth digits
    .replace(/(\d{4})(\d)/, '$1-$2') //Add a hyphen after the block of four digits
}

export const maskCPF = (value) => {
  return (
    value
      .replace(/\D/g, '') //Remove anything that is not a digit
      .replace(/(\d{3})(\d)/, '$1.$2') //Add a dot between the third and fourth digits
      .replace(/(\d{3})(\d)/, '$1.$2') ////Add a dot between the third and fourth digits
      // (again, for the second block of numbers)
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  ) //Add a hyphen between the third and fourth digits
}

export const maskCPFOrCNPJ = (value) => {
  let normalize = value.replace(/\D/g, '')

  if (normalize.length > 14) {
    normalize = normalize.slice(0, 14)
  }

  if (normalize.length <= 11) {
    return maskCPF(normalize)
  }

  return maskCNPJ(normalize)
}
