import { Box, Hidden, useMediaQuery } from '@material-ui/core'
import { installmentDefault } from 'components/PaymentForm/CreditCardForm'
import { usePaymentContext } from 'context/PaymentContext'
import { useSelector } from 'react-redux'
import { selectPayments } from 'reducers/payments'
import { maskCNPJ } from 'utils/form-masks'
import hasInterestAndFines from 'utils/helpers/hasInterestAndFines'
import invoiceStatus from 'utils/invoiceStatus'
import { ProcessingBox } from '../PaymentForm/styles'
import { PaymentButton } from './PaymentButton'
import {
  PaymentAmountSubTitle,
  PaymentInfoAmount,
  PaymentInfoDocumentNumber,
  PaymentInfoFantasyName,
  PaymentInfoHandle,
  PaymentInfoSubTitle,
  PaymentInfoTitle,
} from './styles'

export function ValidAmount(userData, invoiceData) {
  const { invoiceData: data } = usePaymentContext()
  const payments = useSelector(selectPayments)
  const isMobile = useMediaQuery('(max-width:600px)', [])

  // checks whether it is to show the value without or with the fees applied
  const showAmountWithoutFees =
    isMobile ||
    invoiceData.dynamicParams?.installments === installmentDefault ||
    !invoiceData.dynamicParams?.installments ||
    payments.methodIndex !== 0

  // check if it is to show how many installments the payment will be made in
  const showInstallments =
    !isMobile &&
    invoiceData.dynamicParams?.installments &&
    invoiceData.dynamicParams?.installments !== installmentDefault &&
    payments.methodIndex === 0 &&
    payments.active

  const showAmountWithInterestAndFines =
    hasInterestAndFines(invoiceData) && invoiceData?.status === invoiceStatus.OVERDUE

  const getDescription = invoiceData.info?.reference || invoiceData?.plan_description

  return (
    <>
      <Box display="flex" flexDirection="column" style={{ gap: '0.6rem' }}>
        <PaymentInfoTitle component="h2">Você está pagando para</PaymentInfoTitle>
        <Box display="flex" flexDirection="column">
          <PaymentInfoHandle component="h2">${userData.id}</PaymentInfoHandle>
          <Box display="flex" flexDirection="column">
            <PaymentInfoFantasyName component="h2">{userData.fantasy_name}</PaymentInfoFantasyName>
            <PaymentInfoDocumentNumber component="h2">
              {userData.document_number > 11
                ? `${maskCNPJ(userData.document_number)}`
                : 'Pessoa Física'}
            </PaymentInfoDocumentNumber>
          </Box>
        </Box>

        {getDescription && getDescription.length > 0 && (
          <PaymentInfoSubTitle component="h3">{`${getDescription}`}</PaymentInfoSubTitle>
        )}
      </Box>
      <Box display="flex" flexDirection="column" style={{ gap: '0.3rem' }}>
        {!showAmountWithInterestAndFines ? (
          <PaymentInfoAmount component="h1">
            <>
              {showAmountWithoutFees
                ? `R$ ${invoiceData.info.amount.toFixed(2).replace('.', ',')}`
                : userData.amount?.formattedValue}
            </>
          </PaymentInfoAmount>
        ) : (
          <>
            <PaymentInfoAmount component="h1">
              {`R$ ${invoiceData.amount.total.toFixed(2).replace('.', ',')}`}
            </PaymentInfoAmount>
            <PaymentInfoSubTitle
              component="h3"
              style={{ fontSize: '14px' }}
            >{`Valor original: R$ ${invoiceData.amount.original
              .toFixed(2)
              .replace('.', ',')}`}</PaymentInfoSubTitle>
          </>
        )}

        {showInstallments && (
          <PaymentAmountSubTitle component="h3">{`${invoiceData.dynamicParams?.installments}x Cartão`}</PaymentAmountSubTitle>
        )}
      </Box>

      {data?.status === invoiceStatus.PROCESSING ? (
        <ProcessingBox
          title={'Pagamento em análise'}
          data={
            'O pagamento do boleto pode levar até 2 dias úteis e um e-mail de confirmação será enviado'
          }
        />
      ) : (
        <Hidden smUp>
          <PaymentButton />
        </Hidden>
      )}
    </>
  )
}
