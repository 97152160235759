import { HeaderLogo, HeaderBar } from './styles'
import config from 'config'

function Header(props) {
  return (
    <HeaderBar {...props}>
      <a href={config.infinitePayLink}>
        <HeaderLogo />
      </a>
    </HeaderBar>
  )
}

export default Header
