import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { deepmerge } from '@material-ui/utils'
import { Button, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import * as MuiFields from 'mui-rff'
import { Form as FinalForm, useField, useForm } from 'react-final-form'
import setFieldData from 'final-form-set-field-data'
import { usePaymentContext } from '../../../context/PaymentContext'
import behaviouralData from 'libs/behavioural'
import theme from 'theme'
import clsx from 'clsx'

export const FormSchemaContext = createContext()

export function useFormSchemaContext() {
  return useContext(FormSchemaContext)
}

export function useFieldForValid(name) {
  return useField(name, {
    subscription: {
      valid: true,
      dirty: true,
      touched: true,
      initial: true,
    },
  })
}

export function useFieldForChange(name) {
  return useField(name, {
    subscription: {
      dirty: true,
      value: true,
      initial: true,
    },
  })
}

export const {
  Autocomplete,
  Checkboxes,
  Radios,
  KeyboardDatePicker,
  DatePicker,
  Switches,
  TimePicker,
  Debug,
} = MuiFields

const Form = ({ validate, required, schema, initialValues: iv, ...props }) => {
  return (
    <FormSchemaContext.Provider
      value={{
        validate: validate,
        required: required,
        schema: schema,
      }}
    >
      <FinalForm
        initialValues={{
          ...iv,
        }}
        validate={validate}
        mutators={{ setFieldData }}
        {...props}
      />
    </FormSchemaContext.Provider>
  )
}

export default Form

function _fieldHelperError({
  meta: { submitError, dirtySinceLastSubmit, error, touched, data, ...options },
}) {
  return !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || data.filled))
}

export function TextField({
  onComplete,
  onBlur,
  onPaste,
  onCopy,
  inputProps = { indeterminate: false },
  ...props
}) {
  const form = useForm()
  const config = usePaymentContext()
  const { indeterminate, ..._inputProps } = inputProps
  const { setFieldData } = form.mutators
  const {
    meta: { valid, dirty },
  } = useFieldForValid(props.name)
  const onCompleteHandler = useCallback(
    (...args) => {
      setFieldData(props.name, {
        filled: true,
      })
      if (!indeterminate) {
        form.change(props.name, args[0])
      }
      if (onComplete) {
        return onComplete(...args)
      }
    },
    [form, props.name, indeterminate, setFieldData, onComplete],
  )
  const onBlurHandler = useCallback(
    (event) => {
      const filled = true && dirty
      setFieldData(props.name, {
        filled: filled,
      })
      if (filled) {
        behaviouralData.stopFillingField(props.name)
      }
      const value = event.currentTarget.value
      if (indeterminate && !config.deviceDetection.device) {
        form.change(props.name, value)
      }
      if (onBlur) {
        return onBlur(event)
      }
    },
    [form, props.name, dirty, setFieldData, indeterminate, onBlur, config.deviceDetection.device],
  )
  const onCopyHandler = useCallback(
    (event) => {
      behaviouralData.addFieldOperation('copy', props.name)
      if (onCopy) {
        return onCopy(event)
      }
    },
    [props.name],
  )
  const onPasteHandler = useCallback(
    (event) => {
      behaviouralData.addFieldOperation('paste', props.name)
      if (onPaste) {
        return onPaste(event)
      }
    },
    [props.name],
  )
  const onFocusHandler = useCallback(
    (event) => {
      const _keyUpTimeout = setTimeout(() => {
        behaviouralData.addFieldOperation('mouse_focus', props.name)
        window.removeEventListener('keyup', _keyUpDetector)
      }, 130)
      const _keyUpDetector = (e) => {
        const code = e.keyCode ? e.keyCode : e.which
        if (code == 9) {
          behaviouralData.addFieldOperation('keyboard_focus', props.name)
        }
        clearTimeout(_keyUpTimeout)
        window.removeEventListener('keyup', _keyUpDetector)
      }
      behaviouralData.startFillingField(props.name)
      window.addEventListener('keyup', _keyUpDetector)
    },
    [props.name],
  )
  return (
    <MuiFields.TextField
      showError={_fieldHelperError}
      {...deepmerge(
        theme.props.MuiTextField,
        deepmerge(
          {
            inputProps: {
              autoComplete: 'off',
              'aria-invalid': !(valid && dirty),
              onComplete: !indeterminate ? onCompleteHandler : undefined,
              onBlur: onBlurHandler,
              onFocus: onFocusHandler,
              onCopy: onCopyHandler,
              onPaste: onPasteHandler,
            },
            InputProps: {
              'aria-invalid': !(valid && dirty),
            },
          },
          {
            inputProps: _inputProps,
            ...props,
          },
        ),
      )}
    />
  )
}

function _helperCreateOptions(data) {
  return (data ?? []).map((item, key) => (
    <option key={`${key}_${item.value}`} value={item.value} disabled={item.disabled}>
      {item.option || item.label?.toString() || item.value}
    </option>
  ))
}

export function Select({ children, defaultValue, onChange, data = [], ...props }) {
  const config = usePaymentContext()
  const form = useForm()
  const {
    meta: { valid, dirty, touched, initial },
  } = useFieldForValid(props.name)
  const [options] = useState(() => {
    return _helperCreateOptions(data)
  })
  const [selected, setSelected] = useState(() => {
    return defaultValue || initial || data?.[0]?.value || ''
  })
  const isDevice = config.deviceDetection.device
  const nativeChildren = data.length && isDevice && options
  const selectProps = { ...deepmerge(theme.props.MuiSelect, props) }
  const selectableDataProps = {
    'data-selected': selected,
    'aria-invalid': !(valid && (dirty || touched || initial !== '')),
  }
  useEffect(() => {
    form.change(props.name, selected)
  }, [])
  return (
    <MuiFields.Select
      {...{
        ...selectProps,
        ...((!nativeChildren && { data }) ?? {}),
      }}
      native={isDevice}
      variant={theme.props?.MuiSelect?.variant || 'standard'}
      SelectDisplayProps={(!isDevice && selectableDataProps) || null}
      inputProps={{
        ...((isDevice && selectableDataProps) ?? {}),
        onChange: (event, child) => {
          const target = typeof event.target.value === 'object' ? event.target.value : event.target
          setSelected(target.value)
          onChange &&
            onChange(
              {
                ...event,
                target: target,
              },
              child,
            )
        },
      }}
      {...props}
    >
      {nativeChildren}
    </MuiFields.Select>
  )
}

export const SubmitButton = withStyles((theme) => ({
  endIcon: {
    position: 'absolute',
    right: '1.25rem',
    top: 0,
    bottom: 0,
    height: 'fit-content',
    lineHeight: 0,
    margin: 'auto 0',
  },
  submitting: {
    '&:after': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(-60deg, rgba(255, 255, 255, 0) 0 20%, rgba(255, 255, 255, .4), rgba(255, 255, 255, 0) 80% 100%)',
      backgroundSize: '150px 100%',
      animationName: 'shimmer',
      animationDuration: '1s',
      animationIterationCount: 'infinite',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 0',
      filter: 'blur(8px)',
    },
  },
  '@global @keyframes shimmer': {
    '0%': {
      backgroundPosition: '-50vw 0',
      backgroundSize: '150px 100%',
    },
    '100%': {
      backgroundPosition: '50vw 0',
      backgroundSize: '150px 100%',
    },
  },
}))(({ children, submitting = false, classes, ...props }) => {
  const { submitting: classSubmitting, ...cls } = classes
  return (
    <Button
      {...props}
      classes={cls}
      className={clsx({
        [classSubmitting]: submitting,
      })}
      fullWidth
      variant="contained"
      color="primary"
      type="submit"
      endIcon={
        submitting && <CircularProgress color="inherit" size={20} thickness={5} disableShrink />
      }
    >
      {submitting ? 'Processando...' : children}
    </Button>
  )
})
