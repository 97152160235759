import { IconButton, SvgIcon } from '@material-ui/core'
import HelpIcon from 'assets/system-help.svg'
import Image from 'next/image'

export const HelpImage = () => {
  return <Image src={HelpIcon} width={20} height={20} alt="Ajuda" />
}

const HelpButton = (props) => {
  return (
    <IconButton aria-label="Sobre o CVV" {...props}>
      <SvgIcon component={HelpImage} />
    </IconButton>
  )
}

export default HelpButton
