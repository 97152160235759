import { Box, Button, SvgIcon, Typography } from '@material-ui/core'
import { styled, withStyles } from '@material-ui/core/styles'
import backgroundVisaMobile from 'assets/banner-visa-mobile.png'
import backgroundVisa from 'assets/banner-visa.png'
import { ReactComponent as LogoIcon } from 'assets/logo-colored.svg'
import { ReactComponent as WarningOverdue } from 'assets/warning-overdue.svg'
import clsx from 'clsx'
import BannerLink from 'components/ui/BannerLink'
import config from 'config'
import { useSelector } from 'react-redux'
import { selectPayments } from 'reducers/payments'

export const HeaderBox = withStyles(
  (theme) => ({
    root: {
      minHeight: '100vh',
      minWidth: '360px',
      width: '100%',
      '& .MuiButton-root': {
        transition: 'opacity 0.369s ease-in-out, visibility 0.369s ease-out',
      },
      [theme.breakpoints.down('md')]: {
        padding: '0 24px',
      },
    },
    shrink: {
      '& .MuiButton-root': {
        opacity: 0,
        visibility: 'hidden',
      },
      '&, html.mobile &': {
        height: 'auto',
        minHeight: 0,
      },
    },
  }),
  {
    name: 'HeaderBox',
  },
)(({ shrink, classes, ...props }) => (
  <Box className={clsx(classes.root, { [classes.shrink]: !shrink })} {...props} />
))

export const DisplayBox = withStyles(
  (theme) => ({
    root: {
      color: theme.palette.common.white,
      width: '100%',
      margin: '0 auto',
      maxWidth: '425px',
      minHeight: '100vh',
      transition: 'max-height 0.369s ease-in-out, min-height 0.369s ease-in-out',
      maxHeight: '100vh',
      gap: '1rem',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '680px',
      },
    },
    shrink: {
      minHeight: '0vh',
      maxHeight: '0vh',
    },
  }),
  {
    name: 'DisplayBox',
  },
)(({ shrink, classes, ...props }) => (
  <Box className={clsx(classes.root, { [classes.shrink]: shrink })} {...props} />
))
export const InnerDisplayBox = styled(Box)(
  ({ theme, ...props }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    margin: '0 auto',
  }),
  {
    name: 'InnerDisplayBox',
  },
)

export const HeaderBar = styled(Box)(
  ({ theme, ...props }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,
    '& > a': {
      display: 'inline-block',
      height: 24,
      width: 117,
    },
    [theme.breakpoints.up('sm')]: {
      height: 80,
      '& > a': {
        display: 'inline-block',
        height: 28,
        width: 137,
      },
    },
  }),
  {
    name: 'HeaderBar',
  },
)

export const HeaderLogo = styled((props) => (
  <SvgIcon component={LogoIcon} viewBox="0 0 178 36" {...props} />
))(
  ({ theme, ...props }) => ({
    padding: 0,
    width: '7.5em',
    display: 'inline-block',
    height: 24,
    width: 117,
    [theme.breakpoints.up('sm')]: {
      height: 28,
      width: 137,
    },
  }),
  {
    name: 'Logo',
  },
)

export const BannerBox = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: theme.palette.common.white,
      background: '#212121',
      width: 'auto',
      height: 'auto',
      margin: '0 1rem',
      padding: '1.5rem',

      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      [theme.breakpoints.up('sm')]: {
        margin: '0',
        padding: '36px 46px',
      },
    }
  },
  {
    name: 'BannerBox',
  },
)

export const PaymentInfoTitle = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#EOEOEO',
      margin: 0,
      fontWeight: 500,
      fontSize: '16px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
      },
    }
  },
  {
    name: 'PaymentInfoTitle',
    component: 'h2',
  },
)

export const PaymentInfoHandle = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#F5F5F5',
      margin: 0,
      fontWeight: 500,
      fontSize: '24px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '26px',
      },
    }
  },
  {
    name: 'PaymentInfoHandle',
    component: 'h2',
  },
)

export const PaymentInfoFantasyName = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#EEEEEE',
      margin: 0,
      fontWeight: 400,
      fontSize: '12px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
    }
  },
  {
    name: 'PaymentInfoFantasyName',
    component: 'h2',
  },
)

export const PaymentInfoDocumentNumber = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#9e9e9e',
      margin: 0,
      fontWeight: 500,
      fontSize: '10px',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '12px',
      },
    }
  },
  {
    name: 'PaymentInfoDocumentNumber',
    component: 'h2',
  },
)

export const PaymentInfoSubTitle = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#9e9e9e',
      margin: 0,
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.3rem',
      textAlign: 'center',
      wordBreak: 'break-all',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '23.4px',
      },
    }
  },
  {
    name: 'PaymentInfoSubTitle',
    component: 'h3',
  },
)

export const PaymentAmountSubTitle = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#E0E0E0',
      margin: 0,
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.3rem',
      textAlign: 'center',
      wordBreak: 'break-all',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '23.4px',
      },
    }
  },
  {
    name: 'PaymentAmountSubTitle',
    component: 'h3',
  },
)

export const PaymentInfoAmount = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#fff',
      margin: 0,
      fontWeight: 500,
      fontSize: '2.5rem',
      lineHeight: '3rem',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        lineHeight: '36px',
      },
    }
  },
  {
    name: 'PaymentInfoAmount',
    component: 'h1',
  },
)

export const PaymentInfoButton = styled(Button)(
  ({ theme, ...props }) => {
    return {
      fontSize: '1rem',
      fontWeight: 500,
      color: '#424242',
      height: '2.2rem',
      [theme.breakpoints.up('sm')]: {
        height: '3rem',
        fontSize: '18px',
        width: 270,
      },
      '&:hover': {
        transition: 'transform 0.7s ease-in-out',
      },
    }
  },
  {
    name: 'PaymentInfoButton',
  },
)

export const BannerButton = styled(Button)(
  ({ theme, ...props }) => {
    return {
      marginTop: '13px',
      fontWeight: 700,
      fontSize: '9.03px',
      height: '25.44px',
      width: '140.28px',
      '&:hover': {
        transition: 'transform 0.7s ease-in-out',
      },
    }
  },
  {
    name: 'BannerButton',
  },
)

export const PaymentInfoErrorTitle = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#fff',
      margin: 0,
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1rem',
      textAlign: 'center',
      opacity: 0.8,
    }
  },
  {
    name: 'PaymentInfoTitle',
    component: 'h2',
  },
)

export const PaymentInfoErrorSubTitle = styled(Box)(
  ({ theme, ...props }) => {
    return {
      color: '#fff',
      margin: 0,
      fontWeight: 400,
      fontSize: '14px',
      opacity: 0.8,
      lineHeight: '1rem',
      textAlign: 'center',
    }
  },
  {
    name: 'PaymentInfoSubTitle',
    component: 'h3',
  },
)
export const FlowMarketingBanner = withStyles(
  ({ theme, ...props }) => {
    return {
      root: {
        textDecoration: 'none',
        transition: '0.369s ease-in-out',
        opacity: 1,
        borderRadius: 8,

        visibility: 'visible',
        position: 'relative',
        width: '100%',
        height: 0,
        paddingBottom: '23.84%',
        background: `transparent url(${backgroundVisa.src}) no-repeat center`,
        backgroundSize: 'auto 100%',
      },
      shrink: {
        marginTop: 16,
      },
      textContainer: {
        textAlign: 'left',
        marginLeft: '6%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '3.2%',
      },
      textContainerActive: {
        textAlign: 'left',
        marginLeft: '55px',
        display: 'flex',
        flexDirection: 'column',
        height: '180px',
        justifyContent: 'center',
      },
      textTypography: {
        fontWeight: 500,
        display: 'inline-block',
        fontSize: '14px',
        lineHeight: '21.23px',
        color: '#000000',
      },
      textTypographyActive: {
        fontWeight: 500,
        display: 'inline-block',
        fontSize: '12px',
        color: '#000000',
      },
      textTypographyTitle: {
        fontSize: '22.24px',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        height: '39px',
        color: '#000000',
        width: '320px',
      },
      textTypographyTitleActive: {
        fontSize: '18px',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        minHeight: '30px',
        color: '#000000',
        marginRight: '250px',
      },
      button: {
        background: '#000000',
        color: '#FFFFFF',
        transition: 'all 0.3s ease-in-out',
        fontSize: '12px',
        height: '30px',
        fontWeight: 700,
        '&:hover': {
          transform: 'scale(0.94)',
          background: '#000000',
        },
      },
    }
  },
  {
    name: 'FlowMarketingBanner',
  },
)(({ shrink, classes, ...props }) => {
  const payments = useSelector(selectPayments)

  return (
    <BannerLink
      href={shrink ? config.infinitePayLinkBanner.desktop : config.infinitePayLinkBanner.home}
      className={clsx(classes.root, { [classes.shrink]: shrink })}
      {...props}
    >
      <Box className={!payments.active ? classes.textContainer : classes.textContainerActive}>
        <Typography
          className={clsx(
            !payments.active ? classes.textTypographyTitle : classes.textTypographyTitleActive,
          )}
        >
          Link de Pagamento InfinitePay
        </Typography>
        <Typography
          className={clsx(!payments.active ? classes.textTypography : classes.textTypographyActive)}
        >
          Venda segura e recebimento na hora. <br />O link favorito de quem vende online.
        </Typography>
        <BannerButton className={classes.button}>Cadastre-se grátis</BannerButton>
      </Box>
    </BannerLink>
  )
})

export const FlowMarketingBannerMobile = withStyles(
  ({ theme, ...props }) => ({
    root: {
      textDecoration: 'none',
      borderRadius: 13,
      display: 'flex',
      width: 'auto',
      margin: '0 1rem',
      height: 150,
      background: `transparent url(${backgroundVisaMobile.src}) no-repeat center`,
      backgroundSize: '100%',
    },
    textContainer: {
      textAlign: 'left',
      marginLeft: '6%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '8%',
    },
    textTypography: {
      fontWeight: 500,
      display: 'inline-block',
      fontSize: '9px',
      lineHeight: '14px',
      color: '#000000',
      width: '171px',
    },
    textTypographyTitle: {
      fontSize: '16px',
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      height: '36px',
      color: '#000000',
      width: '180px',
      lineHeight: '18px',
    },
    button: {
      background: '#000000',
      color: '#FFFFFF',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(0.94)',
        background: '#000000',
      },
      height: '21px',
      width: '118px',
      fontSize: '8px',
      marginTop: '5px',
    },
  }),
  {
    name: 'FlowMarketingBanner',
  },
)(({ classes, ...props }) => (
  <BannerLink href={config.infinitePayLinkBanner.home} className={classes.root} {...props}>
    <Box className={classes.textContainer}>
      <Typography className={clsx(classes.textTypographyTitle)}>
        Link de Pagamento InfinitePay
      </Typography>
      <Typography className={clsx(classes.textTypography)}>
        Venda segura e recebimento na hora. <br />O link favorito de quem vende online.
      </Typography>
      <BannerButton className={classes.button}>Cadastre-se grátis</BannerButton>
    </Box>
  </BannerLink>
))

const overdueStyles = (theme) => ({
  root: (props) => ({
    color: '#E0E0E0',
    fontWeight: '500',
    fontSize: '16px',
    padding: '16px',
    borderRadius: '16px',
    backgroundColor: '#212121',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    margin: '0 16px',
    justifyContent: props.showInterestAndFines ? 'flex-start' : 'center',
    [theme.breakpoints.up('sm')]: {
      margin: '0',
    },
  }),
  interestAndFines: {
    color: '#C7C7C7',
    fontSize: '14px',
    display: 'block',
  },
})

const OverdueInfo = withStyles(overdueStyles, { name: 'OverdueInfo' })(({ classes, ...props }) => {
  return (
    <Box className={clsx(classes.root, props.className)}>
      <WarningOverdue />
      <div>
        Pagamento Atrasado
        {props.showInterestAndFines && (
          <span className={classes.interestAndFines}>Foram adicionadas taxas a sua cobrança</span>
        )}
      </div>
    </Box>
  )
})

export { OverdueInfo }
