import { useCallback } from 'react'
import useRudderStack from 'utils/useRudderStack'

function BannerLink(props) {
  const { rudderStack, ready: readyRudderStack } = useRudderStack()
  const onClick = useCallback(() => {
    if (readyRudderStack && rudderStack) {
      rudderStack?.track('Invoice Banner')
    }
  }, [readyRudderStack, rudderStack])
  return <a {...props} onClick={onClick} target="_blank" />
}

export default BannerLink
