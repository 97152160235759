import currency from 'currency-formatter'
import { format, parseISO } from 'date-fns'

export const formatDate = (dateString) => {
  const date = parseISO(dateString)
  return format(date, 'dd/MM/yyyy')
}

export const formatPercentage = (value) => {
  return `${value.toFixed(2).replace('.', ',')}%`
}

export const formatCurrency = (value) => {
  return currency.format(value, { code: 'BRL', precision: 2 })
}
