import { Box, Hidden } from '@material-ui/core'
import Footer from 'components/Footer'
import { usePaymentContext } from 'context/PaymentContext'
import hasInterestAndFines from 'utils/helpers/hasInterestAndFines'
import invoiceStatus from 'utils/invoiceStatus'
import HeaderBar from './HeaderBar'
import { MarketingBanner } from './MarketingBanner'
import { PaymentButton } from './PaymentButton'
import PaymentInfo from './PaymentInfo'
import { DisplayBox, InnerDisplayBox, OverdueInfo } from './styles'

function Display({ shrink, ...props }) {
  const { invoiceData } = usePaymentContext()
  const showInterestAndFines = hasInterestAndFines(invoiceData)

  return (
    <DisplayBox shrink={shrink} {...props}>
      <InnerDisplayBox>
        <HeaderBar />
        <Box display="flex" flexDirection="column" style={{ gap: '1rem' }}>
          {showInterestAndFines && (
            <>
              {invoiceData && invoiceData.status === invoiceStatus.OVERDUE && (
                <OverdueInfo showInterestAndFines={showInterestAndFines} />
              )}
            </>
          )}
          <PaymentInfo />
          {invoiceData?.status !== invoiceStatus.PROCESSING && (
            <Hidden xsDown>
              <Box textAlign="center">
                <PaymentButton />
              </Box>
            </Hidden>
          )}
          <MarketingBanner />
        </Box>
        <Footer color="light" />
      </InnerDisplayBox>
    </DisplayBox>
  )
}

export default Display
