import { withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

export const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
    '.mobile &': {
      overflowY: 'scroll',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      '& > *': {
        flex: '1 1 auto',
      },
    },
  },
})

function LandingBox({ classes, children, ...props }) {
  return (
    <Box {...props} className={classes.container}>
      {children}
    </Box>
  )
}

export default withStyles(styles, { name: 'LandingBox' })(LandingBox)
