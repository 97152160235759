import { Box, Typography, SvgIcon } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { ReactComponent as LogoIcon } from '../assets/logo-gray.svg'
import config from 'config'
import { usePaymentContext } from 'context/PaymentContext'

export const LogoGrey = styled((props) => (
  <SvgIcon component={LogoIcon} viewBox="0 0 58 12" {...props} />
))(
  ({ theme, ...props }) => ({
    padding: 0,
    width: '2.6em',
  }),
  {
    name: 'Logo',
  },
)

const FooterBox = styled(Box)(
  ({ theme, color, ...props }) => ({
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    height: '1.25rem',
    margin: '1rem auto',
    justifyContent: 'center',
    '& a': {
      display: 'inline-flex',
    },
    '& a:nth-child(2), & a:nth-child(3)': {
      height: '100%',
      color: color === 'light' ? theme.palette.common.white : theme.palette.text.secondary,
      '&:before': {
        content: "''",
        margin: '0 0.6666666667em',
        display: 'inline-block',
        height: '100%',
        width: '1px',
        backgroundColor: theme.palette.grey['300'],
      },
    },
    '& svg, & svg path': {
      fill: color === 'light' ? theme.palette.common.white : theme.palette.text.secondary,
    },
  }),
  {
    name: 'Footer',
  },
)

function Footer({ ...props }) {
  return (
    <FooterBox component="footer" {...props}>
      <a href={config.infinitePayLink}>
        <LogoGrey height="12" width="58" />
      </a>
      <Typography
        variant="caption"
        color="textSecondary"
        component="a"
        href="https://www.infinitepay.io/termos-de-privacidade-e-condicoes-de-uso/"
        target="_blank"
      >
        Termos e Privacidade
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        component="a"
        href="https://ajuda.infinitepay.io/pt-BR/articles/4739312-como-pagar-um-link-de-pagamento"
        target="_blank"
      >
        Precisa de ajuda?
      </Typography>
    </FooterBox>
  )
}

export default Footer
