import { BannerBox } from './styles'
import { PaymentContent } from './PaymentContent'

function PaymentInfo({ ...props }) {
  return (
    <>
      <BannerBox {...props}>
        <PaymentContent />
      </BannerBox>
    </>
  )
}

export default PaymentInfo
