import { PaymentInfoButton } from './styles'

import { usePaymentContext } from 'context/PaymentContext'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { showForm } from 'reducers/payments'
import canPay from 'utils/canPay'
import invoiceStatus from 'utils/invoiceStatus'
import useRudderStack from 'utils/useRudderStack'

export function PaymentButton() {
  const { userData, invoiceData } = usePaymentContext()
  const showPayButton = canPay(userData, invoiceData)
  const { rudderStack, ready: rudderStackReady } = useRudderStack()
  const dispatch = useDispatch()

  const handlePay = useCallback(async () => {
    if (rudderStackReady) {
      rudderStack?.track('Invoice Pay Now', {
        invoice_id: invoiceData.slug,
      })
    }
    await dispatch(showForm())
  }, [dispatch, rudderStackReady])

  return (
    <>
      {showPayButton && (
        <PaymentInfoButton variant="contained" size="medium" onClick={handlePay}>
          {invoiceData?.status !== invoiceStatus.OVERDUE
            ? 'Pagar agora'
            : 'Pagar cobrança atrasada'}
        </PaymentInfoButton>
      )}
    </>
  )
}
