import { isValid } from '@fnando/cpf'

export const validCNPJ = (value) => {
  if (!value) return false

  // Accepts receiving the value as a string, number or array with all digits
  const isString = typeof value === 'string'
  const validTypes = isString || Number.isInteger(value) || Array.isArray(value)

  // Delete value in invalid format
  if (!validTypes) return false

  // Initial filter for inputs of type string
  if (isString) {
    // Limits to a maximum of 18 characters, for formatted CNPJ
    if (value.length > 18) return false

    // Test Regex to see if it's a valid digits-only string
    const digitsOnly = /^\d{14}$/.test(value)
    // Test Regex to see if it's a valid formatted string
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value)

    // If the format is valid, use a trick to follow the validation flow
    if (digitsOnly || validFormat) true
    // If not, returns invalid
    else return false
  }

  // Save an array with all digits of the value
  const match = value.toString().match(/\d/g)
  const numbers = Array.isArray(match) ? match.map(Number) : []

  // Validate the number of digits
  if (numbers.length !== 14) return false

  // Eliminate invalids with all digits the same
  const items = Array.from(new Set(numbers))

  if (items.length === 1) return false

  // Validator calculation
  const calc = (x) => {
    const slice = numbers.slice(0, x)
    let factor = x - 7
    let sum = 0

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i]

      sum += n * factor--

      if (factor < 2) factor = 9
    }

    const result = 11 - (sum % 11)

    return result > 9 ? 0 : result
  }
  // Separate the last 2 digits of checkers
  const digits = numbers.slice(12)

  // Validate 1st. verifying digit
  const digit0 = calc(12)

  if (digit0 !== digits[0]) return false

  // Validate 2nd. verifying digit
  const digit1 = calc(13)

  return digit1 === digits[1]
}

export const validCPF = (cpf) => {
  return isValid(cpf)
}

export const validCPFOrCNPJ = (value) => {
  return value.length <= 14 ? validCPF(value) : validCNPJ(value)
}
