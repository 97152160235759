import { makeRequired } from 'mui-rff'
import * as Y from 'yup'
import cardValidator from 'card-validator'
import behaviouralData from 'libs/behavioural'
import config from 'config'
import m from './messages'
import { makeValidate } from '../utils'
import * as DamerauLevenshtein from 'damerau-levenshtein-js'
import { parse, isValid as isValidDate } from 'date-fns'
import { validCPFOrCNPJ } from '../../../../utils/form-validation'
import { installmentDefault } from '../../../../components/PaymentForm/CreditCardForm'
import linkApi from 'libs/infinitepay/link'

const EMAIL_VALIDATION_REGEXP =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

Y.addMethod(Y.string, 'emailRuby', function validateEmail(message) {
  return this.matches(EMAIL_VALIDATION_REGEXP, {
    message,
    name: 'email',
    excludeEmptyString: true,
  })
})

const getBankSlipDocumentValidationSchema = () => {
  return Y.string()
    .transform(function (value, originalValue) {
      return validCPFOrCNPJ(value) && this.isType(value) ? originalValue.replace(/\D/gi, '') : null
    })
    .typeError(m.card_holder_document.invalid)
    .min(11, m.card_holder_document.length)
    .required(m.card_holder_document.required)
}

const TYPO_CHECK_DOMAINS = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com.br',
  'cloudwalk.io',
  'infinitepay.io',
]
const PRE_APPROVED_DOMAINS = TYPO_CHECK_DOMAINS.concat([
  'bol.com.br',
  'brturbo.com.br',
  'globo.com',
  'icloud.com',
  'live.com',
  'ig.com.br',
  'msn.com',
  'outlook.com',
  'outlook.com.br',
  'terra.com.br',
  'uol.com.br',
  'yahoo.com',
  'ymail.com',
])

export const initialValues = {
  phone_number: '',
  email: '',
  installments: '',
  card_number: '',
  card_date: '',
  card_cvv: '',
  card_holder_name: '',
  card_holder_document: '',
  billing_zip_code: '',
  billing_street: '',
  billing_city: '',
  billing_number: '',
  billing_state: '',
}

export const UFStates = [
  {
    value: '',
    label: 'Selecione',
  },
].concat(
  Object.entries(config.UF).map((item, key) => {
    return {
      value: item[0],
      label: item[0],
    }
  }),
)
export const validateDomainEmail = (value) => {
  if (typeof value === 'string' || value instanceof String) {
    const domain = value.split('@').pop()
    if (PRE_APPROVED_DOMAINS.includes(domain)) return true
    const someTypoError = TYPO_CHECK_DOMAINS.some((d) => {
      return DamerauLevenshtein.distance(d, domain) === 1
    })
    return !someTypoError
  }
  return false
}
export const schema = Y.object().shape({
  phone_number: Y.string().min(15, m.phone_number.length).required(m.phone_number.required),

  email: Y.string()
    .transform(function (value, originalValue) {
      return (value || '').toLowerCase().trim()
    })
    .email(m.email.invalid)
    .emailRuby(m.email.invalid)
    .test('typo-email', 'E-mail domínio inválido', validateDomainEmail)
    .required(m.email.required),

  installments: Y.string()
    .test('minimun-amount', m.installments.minimun_amount, function (value, context) {
      const meta = (context.schema.spec.meta ||= { amount: null })
      if (meta.amount && value !== installmentDefault) {
        return meta.amount / Number(value) >= 1
      } else {
        return true
      }
    })
    .transform((value, originalValue) => {
      return !value || value === '' ? undefined : value
    })
    .required((value) => {
      return !value || value === installmentDefault ? m.installments.required : true
    }),

  card_number: Y.string()
    .transform(function (value, originalValue) {
      return cardValidator.number(originalValue.trim()).isValid && this.isType(value) ? value : null
    })
    .typeError(m.card_number.invalid)
    .required(m.card_number.required),

  card_date: Y.string()
    .test('expired-date', m.card_date.expired, (value) => {
      return cardValidator.expirationDate(value).isValid ? value : undefined
    })
    .test('invalid-date', m.card_date.invalid, (value) => {
      const date = parse(value, 'MM/y', new Date())
      return isValidDate(date) ? value : undefined
    })
    .min(5, m.card_date.length)
    .required(m.card_date.required),

  card_cvv: Y.string().min(3, m.card_cvv.invalid).required(m.card_cvv.required),

  card_holder_name: Y.string().required(m.card_holder_name.required),

  card_holder_document: getBankSlipDocumentValidationSchema(),

  billing_zip_code: Y.string()
    .min(9, m.billing_zip_code.length)
    .required(m.billing_zip_code.required),

  billing_street: Y.string().required(m.billing_street.required),

  billing_city: Y.string().required(m.billing_city.required),

  billing_number: Y.string().max(20, m.billing_number.length).required(m.billing_number.required),

  billing_state: Y.string()
    .transform(function (value, originalValue) {
      return config.UF[value.toUpperCase()] ? value.toUpperCase() : null
    })
    .typeError(m.billing_state.invalid)
    .required(m.billing_state.required),
})

export function createPayload(formData = {}, invoiceData = null) {
  const cardDate = formData.card_date?.replace(/\D/gi, '')

  //fingerprint
  const {
    components: { canvas, text, ...comps },
    ...fingerprint
  } = config.fingerprint
  Object.entries(comps).forEach((item) => {
    comps[item[0]] = Array.isArray(item[1].value)
      ? typeof item[1].value[0] === 'object'
        ? item[1].value
        : item[1].value.toString()
      : item[1]
  })

  //behavioural
  const behavioural = behaviouralData.getData()

  return {
    payment: {
      payment_method: 'credit',
      capture_method: 'payment_link_web',
      installments: formData.installments,
      nsu: linkApi.getNSU(),
    },
    card: {
      card_number: formData.card_number?.replace(/\D/gi, ''),
      card_holder_name: formData.card_holder_name?.toUpperCase(),
      card_expiration_month: cardDate.substr(0, 2),
      card_expiration_year: cardDate.substr(2),
      cvv: formData.card_cvv,
    },
    billing_details: {
      email: formData.email,
      name: formData.card_holder_name,
      document_number: formData.card_holder_document.replace(/\D/gi, ''),
      phone_number: '+' + formData.phone_number.replace(/\D/gi, ''),
      address: {
        line1: `${formData.billing_street} ${formData.billing_number}`,
        line2: '',
        zip: formData.billing_zip_code?.replace(/\D/gi, ''),
        city: formData.billing_city,
        state: formData.billing_state,
        country: 'BR',
      },
    },
    metadata: {
      ...behavioural,
      risk: {
        session_id: config.sessionId,
        fingerprint: {
          ...fingerprint,
          ...comps,
        },
      },
      origin: invoiceData ? 'invoice' : 'link',
      invoice: invoiceData
        ? {
            type: invoiceData.type,
            slug: invoiceData.slug,
          }
        : null,
      card_address: {
        address: formData.billing_street,
        complement: '',
        number: formData.billing_number,
        zip_code: formData.billing_zip_code?.replace(/\D/gi, ''),
        city: formData.billing_city,
        state: formData.billing_state,
        country: 'BR',
      },
      rid: invoiceData.rid || null,
    },
  }
}

export const validate = makeValidate(schema)

export const required = makeRequired(schema)
